import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import CaseStudyText from "../../screens/resources/case-study/sti-female-patient-tina"

const CaseStudy = () => {
    return (
    <React.Fragment>
      <CaseStudyText />
    </React.Fragment>
  )
}
export default CaseStudy

export const Head = () => (
  <Seo
        title="Patient Case Study 4: Undertreatment of STIs"
        description="Tina is a recently married 27-year-old woman who has dreams of having large family. She presents to the local clinic with complaints of vaginal discharge and irritation."
        image="/meta/case-study-tina.jpg"
  />
)
